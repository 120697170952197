@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Monument";
    font-weight: 600;
    src: url("./fonts/Monument/MonumentExtended-Bold.otf");
}
@font-face {
    font-family: "Monument";
    font-weight: 800;
    src: url("./fonts/Monument/MonumentExtended-Ultrabold.otf");
}

@font-face {
    font-family: "ZwoPro";
    font-weight: 400;
    src: url("./fonts/ZwoPro/ZwoPro.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 600;
    src: url("./fonts/ZwoPro/ZwoPro-Bold.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 600;
    font-style: italic;
    src: url("./fonts/ZwoPro/ZwoPro-BoldItalic.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 400;
    font-style: italic;
    src: url("./fonts/ZwoPro/ZwoPro-Italic.otf");
}

html {
    font-size:5px
}
@media (min-width:1024px) {
    html {
        font-size:9px
    }
}
@media (min-width:1366px) {
    html {
        font-size:17px
    }
}

/** Animations **/
.disappear-enter {
    opacity: 0.01;
}
.disappear-enter.disappear-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.disappear-leave {
    opacity: 1;
}
.disappear-leave.disappear-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

@keyframes bubble1 {
    0%   {opacity: 0}
    50%  {opacity: 0}
    51%  {opacity: 1}
    100% {opacity: 1}
}

@keyframes bubble2 {
    0%   {opacity: 0}
    50%  {opacity: 0}
    51%  {opacity: 1}
    100% {opacity: 1}
}

@keyframes bubble3 {
    0%   {opacity: 0}
    50%  {opacity: 0}
    51%  {opacity: 1}
    100% {opacity: 1}
}

@keyframes bubble4 {
    0%   {opacity: 0}
    50%  {opacity: 0}
    51%  {opacity: 1}
    100% {opacity: 1}
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes wobble {
    0% { transform: translateX(0%); }
    15% { transform: translateX(-25%) rotate(-5deg); }
    30% { transform: translateX(20%) rotate(3deg); }
    45% { transform: translateX(-15%) rotate(-3deg); }
    60% { transform: translateX(10%) rotate(2deg); }
    75% { transform: translateX(-5%) rotate(-1deg); }
    100% { transform: translateX(0%); }
}