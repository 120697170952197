*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto ;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-\[46\%\] {
  top: 46%;
}

.left-20 {
  left: 5rem;
}

.top-\[calc\(50\%-15px\)\] {
  top: calc(50% - 15px);
}

.right-20 {
  right: 5rem;
}

.left-\[calc\(50\%\+47px\)\] {
  left: calc(50% + 47px);
}

.-top-\[200px\] {
  top: -200px;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/4 {
  top: 25%;
}

.top-\[370px\] {
  top: 370px;
}

.left-\[50px\] {
  left: 50px;
}

.top-\[50\%\] {
  top: 50%;
}

.left-16 {
  left: 4rem;
}

.right-48 {
  right: 12rem;
}

.right-16 {
  right: 4rem;
}

.-right-\[5rem\] {
  right: -5rem;
}

.-top-\[5rem\] {
  top: -5rem;
}

.top-\[95px\] {
  top: 95px;
}

.left-\[calc\(50\%-120px\)\] {
  left: calc(50% - 120px);
}

.left-\[calc\(50\%\+15px\)\] {
  left: calc(50% + 15px);
}

.-top-\[80px\] {
  top: -80px;
}

.right-32 {
  right: 8rem;
}

.top-\[155px\] {
  top: 155px;
}

.right-12 {
  right: 3rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-20 {
  z-index: 20;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.-mb-72 {
  margin-bottom: -18rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[2\.5rem\] {
  margin-bottom: 2.5rem;
}

.mt-\[73px\] {
  margin-top: 73px;
}

.mt-10 {
  margin-top: 2.5rem;
}

.-mr-\[9rem\] {
  margin-right: -9rem;
}

.-mt-\[8rem\] {
  margin-top: -8rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mt-\[10rem\] {
  margin-top: -10rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.-mt-\[4\.5rem\] {
  margin-top: -4.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.aspect-\[16\/9\.5\] {
  aspect-ratio: 16 / 9.5;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-64 {
  height: 16rem;
}

.h-1\/2 {
  height: 50%;
}

.h-\[70\%\] {
  height: 70%;
}

.max-h-\[45rem\] {
  max-height: 45rem;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-\[9rem\] {
  width: 9rem;
}

.w-\[14rem\] {
  width: 14rem;
}

.w-\[73rem\] {
  width: 73rem;
}

.w-\[30rem\] {
  width: 30rem;
}

.w-1\/2 {
  width: 50%;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-max {
  width: max-content;
}

.w-\[40rem\] {
  width: 40rem;
}

.min-w-\[30rem\] {
  min-width: 30rem;
}

.min-w-\[18rem\] {
  min-width: 18rem;
}

.min-w-\[11rem\] {
  min-width: 11rem;
}

.max-w-\[8rem\] {
  max-width: 8rem;
}

.max-w-\[7rem\] {
  max-width: 7rem;
}

.max-w-none {
  max-width: none;
}

.max-w-\[60rem\] {
  max-width: 60rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-\[39rem\] {
  max-width: 39rem;
}

.max-w-\[6rem\] {
  max-width: 6rem;
}

.max-w-\[7\.5rem\] {
  max-width: 7.5rem;
}

.max-w-\[90rem\] {
  max-width: 90rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[53\.5rem\] {
  max-width: 53.5rem;
}

.max-w-\[5\.5rem\] {
  max-width: 5.5rem;
}

.max-w-\[3rem\] {
  max-width: 3rem;
}

.max-w-\[100rem\] {
  max-width: 100rem;
}

.max-w-\[9rem\] {
  max-width: 9rem;
}

.max-w-\[84rem\] {
  max-width: 84rem;
}

.max-w-\[16rem\] {
  max-width: 16rem;
}

.max-w-\[18rem\] {
  max-width: 18rem;
}

.max-w-\[30\%\] {
  max-width: 30%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-\[60deg\] {
  --tw-rotate: 60deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.animate-\[bounceIn_1s_ease\] {
  animation: bounceIn 1s;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.gap-12 {
  gap: 3rem;
}

.gap-4 {
  gap: 1rem;
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse) );
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse) );
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse) );
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[55px\] {
  border-radius: 55px;
}

.rounded-\[45px\] {
  border-radius: 45px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.border-\[6px\] {
  border-width: 6px;
}

.border-2 {
  border-width: 2px;
}

.border-none {
  border-style: none;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity) );
}

.border-freiburg-black {
  --tw-border-opacity: 1;
  border-color: rgb(14 14 16 / var(--tw-border-opacity) );
}

.bg-freiburg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(213 101 77 / var(--tw-bg-opacity) );
}

.bg-freiburg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(221 175 39 / var(--tw-bg-opacity) );
}

.bg-freiburg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(70 135 127 / var(--tw-bg-opacity) );
}

.bg-freiburg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(14 14 16 / var(--tw-bg-opacity) );
}

.bg-freiburg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 231 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.object-cover {
  object-fit: cover;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: .5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-\[5\.8rem\] {
  padding-left: 5.8rem;
  padding-right: 5.8rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-ZwoPro {
  font-family: ZwoPro, sans-serif;
}

.font-Monument {
  font-family: Monument, sans-serif;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[2\.75rem\] {
  font-size: 2.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[14rem\] {
  font-size: 14rem;
}

.text-\[2\.8rem\] {
  font-size: 2.8rem;
}

.text-\[115px\] {
  font-size: 115px;
}

.text-\[84px\] {
  font-size: 84px;
}

.text-\[72px\] {
  font-size: 72px;
}

.text-\[100px\] {
  font-size: 100px;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[1rem\] {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-\[1\.75rem\] {
  font-size: 1.75rem;
}

.font-extrabold {
  font-weight: 800;
}

.font-bold {
  font-weight: 700;
}

.leading-\[3\.2rem\] {
  line-height: 3.2rem;
}

.leading-tight {
  line-height: 1.25;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-freiburg-white {
  --tw-text-opacity: 1;
  color: rgb(236 236 231 / var(--tw-text-opacity) );
}

.text-freiburg-red {
  --tw-text-opacity: 1;
  color: rgb(213 101 77 / var(--tw-text-opacity) );
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Monument;
  font-weight: 600;
  src: url("MonumentExtended-Bold.4d53ab71.otf");
}

@font-face {
  font-family: Monument;
  font-weight: 800;
  src: url("MonumentExtended-Ultrabold.6901c97a.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 400;
  src: url("ZwoPro.cc2f3268.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 600;
  src: url("ZwoPro-Bold.4400a828.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 600;
  font-style: italic;
  src: url("ZwoPro-BoldItalic.8612f71e.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 400;
  font-style: italic;
  src: url("ZwoPro-Italic.bd168097.otf");
}

html {
  font-size: 5px;
}

@media (min-width: 1024px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 1366px) {
  html {
    font-size: 17px;
  }
}

.disappear-enter {
  opacity: .01;
}

.disappear-enter.disappear-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.disappear-leave {
  opacity: 1;
}

.disappear-leave.disappear-leave-active {
  opacity: .01;
  transition: opacity .3s ease-in;
}

@keyframes bubble1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bubble2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bubble3 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bubble4 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    transform: translateX(20%)rotate(3deg);
  }

  45% {
    transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    transform: translateX(10%)rotate(2deg);
  }

  75% {
    transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:bg-freiburg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 231 / var(--tw-bg-opacity) );
}

.hover\:bg-freiburg-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(70 135 127 / var(--tw-bg-opacity) );
}

.hover\:bg-freiburg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(213 101 77 / var(--tw-bg-opacity) );
}

/*# sourceMappingURL=index.a7d56106.css.map */
